import { ScrollableNavigationBar } from "@/components/pure/navigation/dfp-scroll-navigation";
import { SubmissionDetail } from "@/components/pure/submission-detail/submissions-detail";
import { DB_ORG } from "@/constants/db";
import { AnalyticsTab } from "@/layouts/signed-in/views/Admin/AnalyticsTab/AnalyticsTab";
import { AssetsTable } from "@/layouts/signed-in/views/Admin/AssetsTable/children/AssetsTable";
import { CushmanDataUpdateTab } from "@/layouts/signed-in/views/Admin/CushmanDataUpdateTab/CushmanDataUpdateTab";
import { MonthlyAuditTab } from "@/layouts/signed-in/views/Admin/MonthlyAuditTab/MonthlyAuditTab";
import { ReportsTab } from "@/layouts/signed-in/views/Admin/ReportsTab/ReportsTab";
import { SettingsTab } from "@/layouts/signed-in/views/Admin/SettingsTab/SettingsTab";
import { UsersTable } from "@/layouts/signed-in/views/Admin/UsersTable/UsersTable";
import { SubmissionsTab } from "@/layouts/signed-in/views/SingleLocation/SubmissionsTab/SubmissionsTab";
import { UserAccessLevel } from "@/stores/user-store";
import { useUserStore } from "@/stores/user-store";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

const Admin = () => {
	const { user } = useUserStore();
	const location = useLocation();

	let tabNames = ["Users", "Submissions", "Assets"];
	if (user?.organization?.external_firebase_id === DB_ORG.CUSHMANWAKEFIELD) {
		tabNames = ["Users", "Submissions", "Data Update", "Monthly Audit"];
	}

	if (user?.role?.access_level === UserAccessLevel.SUPER_ADMIN) {
		if (user?.organization?.external_firebase_id !== DB_ORG.CUSHMANWAKEFIELD) {
			tabNames = [...tabNames, "Reports"];
		}
	}

	tabNames.push("Analytics");
	tabNames.push("Settings");

	if (!user?.role || user?.role?.access_level < UserAccessLevel.ADMIN) {
		return <Navigate to="/plans" replace />;
	}

	return (
		user?.organization && (
			<div className="px-2">
				<ScrollableNavigationBar basePath={"/admin"} tabNames={tabNames} />
				<div className="fullSize">
					<Routes>
						<Route path="users" element={<UsersTable />} />
						<Route
							path="submissions"
							element={<SubmissionsTab organization={user?.organization} />}
						/>
						<Route
							path="submissions/:submissionId"
							element={<SubmissionDetail />}
						/>
						<Route
							path="assets"
							element={
								<AssetsTable browserLocationId={location.state?.locationId} />
							}
						/>
						<Route
							path="data-update"
							element={
								<CushmanDataUpdateTab organization={user?.organization} />
							}
						/>
						<Route
							path="monthly-audit"
							element={<MonthlyAuditTab organization={user?.organization} />}
						/>
						<Route
							path="reports"
							element={<ReportsTab organization={user?.organization} />}
						/>
						<Route path="settings" element={<SettingsTab />} />
						<Route path="analytics" element={<AnalyticsTab />} />
						<Route path="*" element={<Navigate to="users" replace />} />
					</Routes>
				</div>
			</div>
		)
	);
};

export { Admin };
