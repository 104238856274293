import { type LocationAggregate } from "@/models/organization/dashboard-analytics/dashboard-analytics.model";
import {
	MarkerClusterer,
	SuperClusterAlgorithm,
} from "@googlemaps/markerclusterer";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { Spin } from "antd";
import {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from "react";
import "./google-heat-map.scss";
import { getClusterSVG, getMarkerSVG } from "./utils/marker-utils";

const mapContainerStyle = {
	width: "100%",
	height: "100%",
	borderRadius: "0 0 0.5rem 0.5rem",
};
const libraries: ("places" | "visualization" | "geometry")[] = [
	"places",
	"visualization",
	"geometry",
];
const DEFAULT_CENTER = { lat: 39.8283, lng: -98.5795 };
const DEFAULT_ZOOM = 4;

interface GoogleHeatmapProps {
	locations: LocationAggregate[];
}

export interface GoogleHeatmapRef {
	resetView: () => void;
}

const GoogleHeatmap = forwardRef<GoogleHeatmapRef, GoogleHeatmapProps>(
	({ locations }, ref) => {
		const [map, setMap] = useState<google.maps.Map | null>(null);
		const [isMapReady, setIsMapReady] = useState(false);
		const markersRef = useRef<google.maps.Marker[]>([]);
		const infoWindowRef = useRef<google.maps.InfoWindow | null>(null);
		const clustererRef = useRef<MarkerClusterer | null>(null);
		const debounceTimeoutRef = useRef<number>();

		const { isLoaded } = useJsApiLoader({
			googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
			libraries: libraries,
		});

		const validLocations = useMemo(() => {
			return locations.filter(
				(location) =>
					location &&
					typeof location.latitude === "number" &&
					typeof location.longitude === "number" &&
					!Number.isNaN(location.latitude) &&
					!Number.isNaN(location.longitude),
			);
		}, [locations]);

		const locationHealthScores = useMemo(() => {
			return new Map(
				validLocations.map((location) => [
					location.id,
					location.health?.healthScore ?? 0,
				]),
			);
		}, [validLocations]);

		const createMarkers = useCallback(() => {
			return validLocations
				.map((location) => {
					try {
						const healthScore = locationHealthScores.get(location.id) ?? 0;
						const svg = getMarkerSVG(healthScore);

						return new google.maps.Marker({
							position: {
								lat: location.latitude ?? 0,
								lng: location.longitude ?? 0,
							},
							icon: {
								url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`,
								scaledSize: new google.maps.Size(24, 24),
								anchor: new google.maps.Point(12, 12),
							},
							optimized: true,
							clickable: true,
						});
					} catch (error) {
						console.error("Error creating marker:", error);
						return null;
					}
				})
				.filter((marker): marker is google.maps.Marker => marker !== null);
		}, [validLocations, locationHealthScores]);

		const handleMapLoad = useCallback((mapInstance: google.maps.Map) => {
			setMap(mapInstance);
			setIsMapReady(true);
		}, []);

		useEffect(() => {
			if (!map || !isMapReady || !validLocations.length) return;

			const markers = createMarkers();

			try {
				if (clustererRef.current) {
					clustererRef.current.clearMarkers();
				}
				markersRef.current.forEach((marker) => marker.setMap(null));

				if (!infoWindowRef.current) {
					infoWindowRef.current = new google.maps.InfoWindow();
				}

				clustererRef.current = new MarkerClusterer({
					map,
					markers,
					algorithm: new SuperClusterAlgorithm({
						radius: 50,
						minZoom: 3,
						maxZoom: 16,
						minPoints: 2,
					}),
					renderer: {
						render: ({ count, position, markers: clusterMarkers }) => {
							try {
								const avgHealthScore = clusterMarkers?.length
									? clusterMarkers.reduce((sum, marker) => {
											const index = markers.indexOf(
												marker as google.maps.Marker,
											);
											return (
												sum +
												(index >= 0
													? (locationHealthScores.get(
															validLocations[index].id,
														) ?? 0)
													: 0)
											);
										}, 0) / clusterMarkers.length
									: 0;

								const svg = getClusterSVG(count, avgHealthScore);
								return new google.maps.Marker({
									position,
									icon: {
										url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`,
										scaledSize: new google.maps.Size(60, 60),
										anchor: new google.maps.Point(30, 30),
									},
									label: null,
									zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
								});
							} catch (error) {
								console.error("Error creating cluster marker:", error);
								return new google.maps.Marker({ position });
							}
						},
					},
				});

				markers.forEach((marker, index) => {
					const location = validLocations[index];
					if (!marker || !location || !location.address1) return;

					const healthScore = locationHealthScores.get(location.id) ?? 0;

					marker.addListener("mouseover", () => {
						if (debounceTimeoutRef.current) {
							window.clearTimeout(debounceTimeoutRef.current);
						}

						debounceTimeoutRef.current = window.setTimeout(() => {
							infoWindowRef.current?.setContent(
								`<div class="info-window-content">
								<strong class="title">${location.address1}</strong><br/>
								<span class="address">${location.city || ""}, ${location.state || ""} ${location.zip || ""}</span><br/>
								<span class="health-score ${healthScore > 70 ? "good" : "poor"}">
									Health Score: ${healthScore}%
								</span>
							</div>`,
							);
							infoWindowRef.current?.open(map, marker);
						}, 100);
					});
				});

				markersRef.current = markers;
			} catch (error) {
				console.error("Error setting up map:", error);
			}

			return () => {
				try {
					if (clustererRef.current) {
						clustererRef.current.clearMarkers();
					}
					markersRef.current.forEach((marker) => marker.setMap(null));
					markersRef.current = [];
					infoWindowRef.current?.close();
					if (debounceTimeoutRef.current) {
						window.clearTimeout(debounceTimeoutRef.current);
					}
				} catch (error) {
					console.error("Error cleaning up map:", error);
				}
			};
		}, [map, isMapReady, validLocations, locationHealthScores, createMarkers]);

		const resetView = useCallback(() => {
			if (map) {
				map.setZoom(DEFAULT_ZOOM);
				map.setCenter(DEFAULT_CENTER);
			}
		}, [map]);

		useImperativeHandle(ref, () => ({
			resetView,
		}));

		if (!isLoaded) {
			return (
				<div className="flex justify-center items-center h-full">
					<Spin tip="Loading Google Maps..." />
				</div>
			);
		}

		const mapOptions: google.maps.MapOptions = {
			zoom: DEFAULT_ZOOM,
			center: DEFAULT_CENTER,
			mapId: "c4d397c0767fb449",
			minZoom: 3,
			maxZoom: 12,
		};

		return (
			<div className="h-full w-full" data-testid="google-heatmap">
				<GoogleMap
					mapContainerStyle={mapContainerStyle}
					options={mapOptions}
					onLoad={handleMapLoad}
				/>
			</div>
		);
	},
);

export { GoogleHeatmap };
