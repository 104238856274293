import * as Sentry from "@sentry/browser";
import type { Context, SendFeedbackParams } from "@sentry/types";
import { ENV, VITE_PACKAGE_VERSION, VITE_SENTRY_DSN } from "./constants/env";

export function initSentry(): void {
	Sentry.init({
		dsn:
			VITE_SENTRY_DSN ||
			"https://80e3bee1d6354cfeb7aa3a5706cb510f@o4504520713633792.ingest.us.sentry.io/4504521180839936",
		environment: ENV || "development",
		// Alternatively, use `process.env.PACKAGE_VERSION` for a dynamic release version
		// if your build tool supports it.
		release: VITE_PACKAGE_VERSION || "2.0.8",
		autoSessionTracking: true,
		attachStacktrace: true,
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration(),
			Sentry.sessionTimingIntegration(),
			Sentry.extraErrorDataIntegration(),
		],
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for tracing.
		tracesSampleRate: 0.55,
		sampleRate: 0.55,
		profilesSampleRate: 0.55,
		// Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
		tracePropagationTargets: [
			/^https:\/\/facilityai\.com/,
			/^https:\/\/propertyecho\.com/,
			/^https:\/\/staging.propertyecho\.com/,
		],

		// Capture Replay for 10% of all sessions,
		// plus for 40% of sessions with an error
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 0.4,
	});
}

export function logError(error: Error | string, options: Context): string {
	return Sentry.captureException(error, options);
}

export function logMessage(message: string, options: Context): string {
	return Sentry.captureMessage(message, options);
}

export function logEvent(event: Sentry.Event, options: Context): string {
	return Sentry.captureEvent(event, options);
}

export function logFeedback(
	feedback: SendFeedbackParams,
	options: Context,
): string {
	return Sentry.captureFeedback(feedback, options);
}
