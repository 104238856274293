import { AlfredsNotes } from "@/layouts/signed-in/AlfredsNotes/AlfredsNotes";
import { type DashboardAnalytics } from "@/models/organization/dashboard-analytics/dashboard-analytics.model";
import { getOrganizationDashboardAnalytics } from "@/services/organization-service";
import { useUserStore } from "@/stores/user-store";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Layout, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./AnalyticsTab.scss";

const AnalyticsTab = () => {
	const user = useUserStore((state) => state.user);
	const navigate = useNavigate();
	const [analytics, setAnalytics] = useState<DashboardAnalytics | null>(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		let isCancelled = false;

		const fetchAnalytics = async () => {
			try {
				setLoading(true);
				const data = await getOrganizationDashboardAnalytics();

				if (!isCancelled) {
					setAnalytics(data);
					setLoading(false);
				}
			} catch (error) {
				console.error("Error fetching dashboard analytics:", error);
				if (!isCancelled) {
					setLoading(false);
				}
			}
		};

		fetchAnalytics();

		return () => {
			isCancelled = true;
		};
	}, [user?.organization?.external_firebase_id]);

	if (!user?.organization || loading) {
		return (
			<div className="loading-container">
				<Spin size="large" />
			</div>
		);
	}

	return (
		<Layout className="dashboard-container">
			<Row gutter={[24, 24]}>
				<Col xs={24} className="mobile-action-button">
					<Button
						type="primary"
						size="large"
						icon={<PlusOutlined />}
						onClick={() => navigate("/forms")}
						block
					>
						Data Capture
					</Button>
				</Col>
				<Col xs={24}>
					<AlfredsNotes analytics={analytics} loading={loading} />
				</Col>
			</Row>
		</Layout>
	);
};

export { AnalyticsTab };
