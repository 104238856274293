import { VITE_ALFRED_SERVICE_URL } from "@/constants/env";
import { auth, db } from "@/services/auth-service";
import { DB_FILTER, DB_PATH } from "@constants/db";
import {
	type DocumentReference,
	addDoc,
	collection,
	doc,
	getCountFromServer,
	getDoc,
	getDocs,
	query,
	setDoc,
	updateDoc,
	where,
} from "firebase/firestore";
import type { SetStateAction } from "react";
import { getAssetEditHistory } from "./asset-service";
import { getSQLForm } from "./form-service";

/**
 * Converts json to b64, used for sending small amounts of json
 * @param json
 * @returns {string || null}
 */
const jsonToBase64 = (json: any) => {
	return btoa(encodeURIComponent(JSON.stringify(json)));
};

export const getSubmission = async (
	organization: string,
	form: string,
	id: string,
) => {
	const snap = await getDoc(
		doc(
			db,
			DB_PATH.ORGANIZATIONS,
			organization,
			DB_PATH.FORMS,
			form,
			DB_PATH.RESPONSES,
			id,
		),
	);
	return snap.data();
};

export const getSubmissions = async (
	organization: string,
	form: string,
	location: unknown,
	tririgaBuildingSystemId: unknown,
	onlyApproved: boolean | undefined,
) => {
	const searchParams = [where(DB_FILTER.DELETED, "==", false)];
	if (location) {
		searchParams.push(where(DB_FILTER.LOCATION_ID, "==", location));
	}
	if (tririgaBuildingSystemId) {
		searchParams.push(
			where(
				DB_FILTER.LOCATION_TRIRIGA_BUILDING_SYSTEM_ID,
				"==",
				tririgaBuildingSystemId,
			),
		);
	}
	if (onlyApproved === true) {
		searchParams.push(where(DB_FILTER.APPROVED, "==", true));
	}
	const responsesSnap = await getDocs(
		query(
			collection(
				db,
				DB_PATH.ORGANIZATIONS,
				organization,
				DB_PATH.FORMS,
				form,
				DB_PATH.RESPONSES,
			),
			...searchParams,
		),
	);
	return responsesSnap;
};

export const getSubmissionsCount = async (
	organization: string,
	form: string,
	location: unknown,
) => {
	const searchParams = [where(DB_FILTER.DELETED, "==", false)];
	if (location) {
		searchParams.push(where(DB_FILTER.LOCATION_ID, "==", location));
	}
	try {
		const responsesSnap = await getCountFromServer(
			query(
				collection(
					db,
					DB_PATH.ORGANIZATIONS,
					organization,
					DB_PATH.FORMS,
					form,
					DB_PATH.RESPONSES,
				),
				...searchParams,
			),
		);
		return responsesSnap.data().count;
	} catch (err) {
		console.log(err);
	}
};

export const getSubmissionWithTag = async (asset_tag: any) => {
	const url = `${VITE_ALFRED_SERVICE_URL}/asset/asset_tag_lookup/${asset_tag}`;
	const response = await fetch(url, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
		},
	});
	if (response.status !== 200) {
		return response.json().then((errorDetails) => {
			throw new Error(
				`Failed to lookup asset by asset tag ${asset_tag}: ${errorDetails.detail}`,
			);
		});
	}
	return await response.json();
};

export const getSubmissionWithFilter = async (
	_organization: any,
	_formId: any,
	filters: { name: any; value: any }[],
	location_id?: number,
) => {
	try {
		const params = new URLSearchParams({
			limit: "-1",
			filters: jsonToBase64(filters),
		});
		if (location_id) {
			params.append("location_id", location_id.toString());
		}
		const url = `${VITE_ALFRED_SERVICE_URL}/asset/search?${params.toString()}`;
		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});
		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(`Failed to search for assets: ${errorDetails.detail}`);
			});
		}

		return await response.json();
	} catch (exception: any) {
		throw new Error(exception);
	}
};

export const addSubmission = async (
	organization: string,
	formId: string,
	data: undefined,
) => {
	const ref = await addDoc(
		collection(
			db,
			DB_PATH.ORGANIZATIONS,
			organization,
			DB_PATH.FORMS,
			formId,
			DB_PATH.RESPONSES,
		),
		data,
	);
	return ref;
};
export const addCushmanSubmission = async (
	submission_id: any,
	inputs: any,
	userLocation: any,
) => {
	try {
		const url = `${VITE_ALFRED_SERVICE_URL}/submission/complete/${submission_id}`;
		const response = await fetch(url, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
			body: JSON.stringify({
				user_location: userLocation,
				data: inputs,
			}),
		});
		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				console.error(errorDetails);
				throw new Error(errorDetails.detail);
			});
		}
		return await response.json();
	} catch (exception: any) {
		throw new Error(exception);
	}
};
export const deleteSubmission = async (submission_id: number) => {
	const url = `${
		import.meta.env.VITE_ALFRED_SERVICE_URL
	}/submission/delete/${submission_id}`;
	const response = await fetch(url, {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
		},
	});

	if (response.status !== 200) {
		return response.json().then((errorDetails) => {
			throw new Error(errorDetails.detail);
		});
	}

	return await response.json();
};

/**
 * Returns submission data with asset data attached to it
 *
 * @param {number} submission_id - submission id
 * @param {boolean} attach_asset - boolean for adding asset data (if needed)
 * @return {object} The query object for use with React Query.
 */
export const getSQLSubmissionWithAsset = async (
	submission_id: any,
	attach_asset = false,
) => {
	try {
		const url = `${
			VITE_ALFRED_SERVICE_URL
		}/submission/lookup/${submission_id}?attach_asset=${attach_asset}`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(
					`Failed to get submission details: ${errorDetails?.detail[0]?.msg || errorDetails?.detail}`,
				);
			});
		}

		return await response.json();
	} catch (exception: any) {
		throw new Error(`Failed to get submission details: ${exception}`);
	}
};

/**
 * Fetches the last submission for a location
 * @param location_id
 * @returns {Promise<any>}
 */
export const getLatestSubmission = async (location_id: any) => {
	try {
		const url = `${VITE_ALFRED_SERVICE_URL}/submission/latest/${location_id}`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(
					`Failed to get latest submission: ${errorDetails.detail}`,
				);
			});
		}

		return await response.json();
	} catch (exception: any) {
		throw new Error(exception);
	}
};

// Retrieves all necessary data for submission render
export const getSubmissionData = async (
	organization: string | number,
	formId = 3,
	submissionId: any,
	isQA: boolean,
	setResponseData: SetStateAction<any>,
	setFormName: SetStateAction<any>,
	setFormLogo: SetStateAction<any>,
	setAddress: SetStateAction<any>,
	setLocationId: SetStateAction<any>,
	setUserLocation: SetStateAction<any>,
	setChosenAssetType: SetStateAction<any>,
	setAssetEdits: SetStateAction<any>,
) => {
	try {
		const data = await getSQLSubmissionWithAsset(submissionId, true);
		setResponseData(data);
		const edits = await getAssetEditHistory(data.asset.id);
		setAssetEdits(edits);
		// Pass original inputs + location.id, userLocation, form name, & reviewed values
		// to the respective states on the client side
		setLocationId(data.location.id);
		if (data.asset.unique_fields?.userLocation) {
			setUserLocation(data.asset?.unique_fields.userLocation);
		} else {
			setUserLocation(null);
		}
		setFormName(data.form.name);

		// Retrieve logo from associated form in database and pass to formLogo state
		const form_data = await getSQLForm(formId);
		setFormLogo(form_data.config.logo);

		// Format location address and pass it to address state
		const addressParts = [];

		if (data.location.address1) {
			addressParts.push(data.location.address1);
		}
		if (data.location.address2) {
			addressParts.push(data.location.address2);
		}

		addressParts.push(data.location.city);
		addressParts.push(`${data.location.state} ${data.location.zip}`);

		const address = addressParts.filter(Boolean).join(", ");
		setAddress(address);

		// Get chosen asset type and pass it to state
		setChosenAssetType(data.asset.organization_asset_type);
	} catch (error) {
		console.error("Failed to fetch submission data:", error);
		setCanEdit(false);
	}
};

// Retrieves all necessary data for Cushman submission render
export const getCushmanSubmissionData = async (
	_formId: any,
	submissionId: any,
	setOriginalInputs: any,
	setInputs: any,
	setFormName: any,
	setFormLogo: any,
	setDateOfVisit: any,
	setAddress: any,
	setSummary: any,
	setReviewed: any,
	setLocationId: any,
	setUserLocation: any,
	setSavedLocation: any,
) => {
	// Get submission from database
	const submission = await getSQLSubmissionWithAsset(submissionId, false);
	// Pass original inputs + location.id, userLocation, form name, & reviewed values
	// to the respective states on the client side
	setOriginalInputs(submission.raw_input);

	setLocationId(submission.location.id);
	// setSavedLocation({ id: submission.location.id, data: submission.location });
	setUserLocation({
		latitude: submission.location.latitude,
		longitude: submission.location.longitude,
	});
	setFormName(submission.form.name);
	setReviewed(submission.is_approved);

	// Reduce submission inputs down to items containing values
	// and then pass it to inputs state
	const reduceQuestions = (questions: any[]) => {
		return questions.reduce(
			(arr: any[], question: { description: any; actionTaken: any }) => {
				if (question.description || question.actionTaken) {
					arr.push(question);
				}
				return arr;
			},
			[],
		);
	};
	const reduceSections = (sections: any[]) => {
		return sections.reduce(
			(
				arr: { title: any; questions: any }[],
				section: { questions: any[]; title: any },
			) => {
				const questions = reduceQuestions(section.questions.flat());
				if (questions.length > 0) {
					arr.push({
						title: section.title,
						questions,
					});
				}
				return arr;
			},
			[],
		);
	};
	const reducePages = (pages: any[]) => {
		return pages.reduce(
			(
				arr: { title?: any; sections?: any }[],
				page: { sections: any; title: any },
			) => {
				const sections = reduceSections(page.sections);
				if (sections.length > 0) {
					arr.push({
						title: page.title,
						sections,
					});
				} else {
					arr.push({});
				}
				return arr;
			},
			[],
		);
	};
	const pages = submission.raw_input;
	const inputs = reducePages(pages);
	setInputs(inputs);

	// Pass the parsed date of visit value to dateOfVisit state
	const date = pages[0].sections[0].questions[1].value;
	const dateOfVisit = new Date(date).toISOString().split("T")[0];
	setDateOfVisit(dateOfVisit);

	// Pass the summary value to summary state
	const summary = pages[2].sections[0].questions[0].value;
	setSummary(summary);

	// Retrieve logo from associated form in database and pass to formLogo state
	const form_data = await getSQLForm(submission.form.id);
	setFormLogo(form_data.config.logo);

	// Format location address and pass it to address state
	let address = "";
	if (submission.location?.address1) {
		address += ` ${submission.location.address1}`;
	}
	if (submission.location?.address2) {
		address += ` ${submission.location.address2}`;
	}
	address += `, ${submission.location?.city}, ${submission.location?.state} ${submission.location?.zip}`;
	setAddress(address);
};

// Updates a submission
export const updateSubmissionReturnAsset = async (
	organization: string,
	submissionId: string,
	formId: string,
	data: any,
) => {
	const submissionRef = doc(
		db,
		DB_PATH.ORGANIZATIONS,
		organization,
		DB_PATH.FORMS,
		formId,
		DB_PATH.RESPONSES,
		submissionId,
	);
	await setDoc(submissionRef, data);
	return { submissionRef };
};

//for checkups, update submission and asset just for field equipmentAssetImage
export const updateSubmissionAndAsset = async (
	organization: string,
	submissionId: string,
	formId: string,
	updatedData: {
		auditLog: any;
		location: { id: any };
		assetCondition: any;
		equipmentAssetImage: any;
		audited: any;
		assetSubArea: any;
		assetLocation: any;
	},
	assetLocationChanged: any,
	assetAreaChanged: boolean,
) => {
	const submissionRef = doc(
		db,
		DB_PATH.ORGANIZATIONS,
		organization,
		DB_PATH.FORMS,
		formId,
		DB_PATH.RESPONSES,
		submissionId,
	);
	const response: any = await getDoc(submissionRef);

	const assetRef = await response?.data()?.assetRef;

	const auditLog = updatedData.auditLog;

	if (assetRef) {
		if (assetLocationChanged) {
			await updateDoc(assetRef, {
				"other.location": updatedData.location,
				"other.assetCondition": updatedData.assetCondition,
				"other.equipmentAssetImage": updatedData.equipmentAssetImage,
				locationId: updatedData.location.id,
				"other.audited": updatedData.audited,
				// 'other.auditLog': auditLog,
			});

			if (assetAreaChanged) {
				if (organization === "cvs.com") {
					await updateDoc(assetRef, {
						"other.assetSubArea": updatedData.assetSubArea,
						"other.auditLog": auditLog,
					});
				} else {
					await updateDoc(assetRef, {
						"other.assetLocation": updatedData.assetLocation,
						"other.auditLog": auditLog,
					});
				}
			}
		} else {
			await updateDoc(assetRef, {
				"other.assetCondition": updatedData.assetCondition,
				"other.equipmentAssetImage": updatedData.equipmentAssetImage,
				// 'locationId': updatedData.location.id,
				"other.audited": updatedData.audited,
				"other.auditLog": auditLog,
			});
		}
	}
	await updateDoc(submissionRef, updatedData);
};

// Updates a Cushman submission
export const updateCushmanSubmission = async (
	submissionId: any,
	inputs: any,
	userLocation: any,
) => {
	try {
		const url = `${VITE_ALFRED_SERVICE_URL}/submission/update/${submissionId}`;
		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
			body: JSON.stringify({
				user_location: userLocation,
				data: inputs,
			}),
		});
		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(errorDetails.detail);
			});
		}
		return await response.json();
	} catch (exception: any) {
		throw new Error(exception);
	}
};

// Updates a Cushman submission's "reviewed" field
export const updateCushmanSubmissionReview = async (
	submissionId: any,
	is_approved: any,
) => {
	try {
		const url = `${VITE_ALFRED_SERVICE_URL}/submission/review/${submissionId}`;
		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
			body: JSON.stringify({
				is_approved: is_approved,
			}),
		});
		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(errorDetails.detail);
			});
		}
		return await response.json();
	} catch (exception: any) {
		throw new Error(exception);
	}
};

// Gets QA doc
export const getQADoc = (organization: string, id: string) => {
	return doc(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.QA, id);
};

// for checkups
export const getSubmissionBasedLocation = async (
	locationId: number,
	assetTag: string,
	formId = 3,
) => {
	// @logan
	try {
		return await searchSubmissions(
			formId,
			undefined,
			0,
			1,
			"assetTag",
			"asc",
			{},
			assetTag,
			{},
			locationId,
			false,
			undefined,
			true,
		);
	} catch (exception: any) {
		console.error(`Failed to get submission based on location: ${exception}`);
	}
};

export const getSubmissionsRC = async (
	organization: string,
	form: string,
	location: unknown,
) => {
	const searchParams = [where(DB_FILTER.DELETED, "==", false)];
	if (location) {
		searchParams.push(where(DB_FILTER.LOCATION_ID, "==", location));
	}

	const responsesSnap = await getDocs(
		query(
			collection(
				db,
				DB_PATH.ORGANIZATIONS,
				organization,
				DB_PATH.FORMS,
				form,
				DB_PATH.RESPONSES,
			),
			...searchParams,
		),
	);
	return responsesSnap;
};

export const deleteSubmissionRC = async (
	submissionRef: DocumentReference<unknown>,
	deletedBy: any,
	deletedDate: any,
) => {
	const response: any = await getDoc(submissionRef);
	// First, mark asset as deleted
	const assetRef: any = await response?.data()?.assetRef;
	if (assetRef) {
		await updateDoc(assetRef, { deleted: true });
	}
	// Second, mark submission as deleted
	await updateDoc(submissionRef, { deleted: true, deletedBy, deletedDate });
};

export const getDynamicSubmissionData = async (
	formId,
	submissionId,
	setSubmissionData,
	setFormName,
	setFormLogo,
	setAddress,
	setDateOfVisit,
	setLocationId,
) => {
	try {
		// Get submission from the database
		const submission = await getSQLSubmissionWithAsset(submissionId, false);

		setLocationId(submission.location_id);

		// Set submission data
		setSubmissionData(
			submission.raw_input || JSON.parse(submission.raw_input[0]),
		);

		// Set form name and logo
		setFormName(submission.form.name);

		// Retrieve logo from associated form in the database and pass to formLogo state
		const form_data = await getSQLForm(submission.form.id);
		setFormLogo(form_data.config.logo);

		// Format location address and pass it to address state
		let address = "";
		if (submission.location?.address1) {
			address += ` ${submission.location.address1}`;
		}
		if (submission.location?.address2) {
			address += ` ${submission.location.address2}`;
		}
		address += `, ${submission.location?.city}, ${submission.location?.state} ${submission.location?.zip}`;
		setAddress(address);

		// Extract date of visit from submission data
		let dateOfVisit = null;
		// Loop through the sections and questions to find dateOfVisit
		for (const section of submission.raw_input[0]?.sections || []) {
			for (const question of section.questions || section.fields || []) {
				if (
					question.key === "dateOfVisit" ||
					question.responseTag === "dateOfVisit" ||
					question.name === "dateOfVisit" ||
					question.label === "Date of Visit"
				) {
					dateOfVisit = question.value;
					break;
				}
			}
			if (dateOfVisit) break;
		}
		if (dateOfVisit) {
			setDateOfVisit(dateOfVisit || submission.end_time);
		} else {
			setDateOfVisit(submission.end_time);
		}
	} catch (error) {
		console.error("Error in getSubmissionDataRC:", error);
		throw error; // So that calling code can handle it
	}
};

//for checkups tagReplacement workflow, update submission and asset just for field ifTagAssetImage and newAssetTag

export const updateTagReplacementFields = async (
	organization: string,
	submissionId: string,
	formId: string,
	updatedData: {
		idTagAssetImage: any;
		newAssetTag: any;
		assetTag: any;
		auditingUser: any;
		auditLog?: any;
	},
) => {
	// console.log("Updated data", updatedData);
	// const submissionRef = doc(
	// 	db,
	// 	DB_PATH.ORGANIZATIONS,
	// 	organization,
	// 	DB_PATH.FORMS,
	// 	formId,
	// 	DB_PATH.RESPONSES,
	// 	submissionId,
	// );
	// const response = await getDoc(submissionRef);
	// const assetRef = response.data()?.assetRef;

	// const auditLog = updatedData.auditLog || [];

	// // Prepare the audit log entry
	// const auditEntry = {
	// 	auditingUser: updatedData.auditingUser || "Unknown User",
	// 	auditedDate: new Date().toISOString(),
	// 	changes: {
	// 		idTagAssetImage: {
	// 			before: response.data()?.idTagAssetImage || "N/A",
	// 			after: updatedData.idTagAssetImage,
	// 		},
	// 		assetTag: {
	// 			before: response.data()?.assetTag || "N/A",
	// 			after: updatedData.newAssetTag,
	// 		},
	// 	},
	// };

	// // Add the audit entry to the audit log
	// auditLog.push(auditEntry);

	// if (assetRef) {
	// 	// Update the asset with the new image, tag and audit log
	// 	await updateDoc(assetRef, {
	// 		"other.idTagAssetImage": updatedData.idTagAssetImage,
	// 		"other.assetTag": updatedData.newAssetTag,
	// 		"other.oldAssetTag": updatedData.assetTag,
	// 		"other.auditLog": auditLog,
	// 		"other.audited": true,
	// 	});
	// }

	// // Update the submission with the new image, tag and audit log
	// await updateDoc(submissionRef, {
	// 	idTagAssetImage: updatedData.idTagAssetImage,
	// 	assetTag: updatedData.newAssetTag,
	// 	oldAssetTag: updatedData.assetTag,
	// 	auditLog: auditLog,
	// 	audited: true,
	// });

	// console.log("Submission and asset successfully updated with audit log");
	console.warn("updateTagReplacementFields is deprecated.");
	return null;
};

export const searchSubmissions = async (
	formId: string | number,
	formMode = 0,
	offset: string | number,
	limit: string | number,
	sort: string,
	sortDirection: string,
	filters: Record<string, unknown>,
	searchValue: string,
	columnFilter: { [x: number]: string[] },
	locationId: number | number[],
	includeDeleted?: boolean | string,
	dateRange?: string | string[],
	showApproved?: boolean | string,
) => {
	try {
		const params = new URLSearchParams({});

		if (formId) params.append("form_id", String(formId));
		if (formMode) params.append("form_mode", formMode.toString());
		if (offset) params.append("offset", offset.toString());
		if (limit) params.append("limit", limit.toString());
		if (sort) params.append("sort", sort);
		if (sortDirection) params.append("sort_direction", sortDirection);
		if (filters) params.append("filters", jsonToBase64(filters));
		if (searchValue) params.append("search_value", searchValue);
		if (columnFilter)
			params.append("column_filter", jsonToBase64(columnFilter));

		if (Array.isArray(locationId) && locationId.length > 0) {
			params.append("location_id", locationId.join(","));
		} else if (locationId) {
			params.append("location_id", locationId.toString());
		}

		if (includeDeleted)
			params.append("include_deleted", String(includeDeleted));
		if (dateRange) params.append("date_range", dateRange.toString());
		if (showApproved !== null) {
			params.append("is_approved", String(showApproved));
		}

		const url = `${VITE_ALFRED_SERVICE_URL}/submission/search?${params.toString()}`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(
					`Failed to search for submissions: ${errorDetails.detail}`,
				);
			});
		}

		return await response.json().then((data) => {
			return { results: data.hits, total: data.total_hits };
		});
	} catch (exception: any) {
		throw new Error(exception);
	}
};

/**
 * Deletes a given array of draft ids
 * @param {array} draftIds
 * @returns {Promise<any>}
 */
export const deleteDrafts = async (draftIds: any[]) => {
	try {
		const params = draftIds.map((id: any) => `ids=${id}`).join("&");
		const url = `${VITE_ALFRED_SERVICE_URL}/self/draft/delete?${params}`;
		const response = await fetch(url, {
			method: "DELETE",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});
		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				console.error(errorDetails);
				throw new Error(errorDetails.detail);
			});
		}
		return await response.json();
	} catch (exception: any) {
		console.error("Failed to delete drafts ", exception);
		throw new Error(exception);
	}
};

interface DraftInsertUpdateArgs {
	form_id: number;
	location_id?: number;
	draft_id?: number | string;
	data: Record<string, unknown>[] | Record<string, unknown>;
}
export const createDraft = async (args: DraftInsertUpdateArgs) => {
	try {
		const url = `${VITE_ALFRED_SERVICE_URL}/self/draft/insert`;
		const response = await fetch(url, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
			body: JSON.stringify(args),
		});
		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				console.error(errorDetails);
				throw new Error(errorDetails.detail);
			});
		}
		return await response.json();
	} catch (exception: any) {
		console.error("Failed to create draft ", exception);
		throw new Error(exception);
	}
};

export const updateDraft = async (args: DraftInsertUpdateArgs) => {
	try {
		const url = `${VITE_ALFRED_SERVICE_URL}/self/draft/update/${args.draft_id}`;
		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
			body: JSON.stringify(args),
		});
		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				console.error(errorDetails);
				throw new Error(errorDetails.detail);
			});
		}
		return await response.json();
	} catch (exception: any) {
		console.error("Failed to update draft ", exception);
		throw new Error(exception);
	}
};

export const submitSubmission = async (
	submissionId: number,
	data: Record<string, unknown>[] | Record<string, unknown>,
) => {
	try {
		const url = `${VITE_ALFRED_SERVICE_URL}/submission/complete/${submissionId}`;
		const response = await fetch(url, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
			body: JSON.stringify({ data }),
		});
		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				console.error(errorDetails);
				throw new Error(errorDetails.detail);
			});
		}
		return await response.json();
	} catch (exception: any) {
		console.error("Failed to submit submission ", exception);
		throw new Error(exception);
	}
};

export const updateSubmission = async (
	submissionId: number,
	data: Record<string, unknown>[] | Record<string, unknown>,
	locationId: number,
) => {
	try {
		const url = `${VITE_ALFRED_SERVICE_URL}/submission/update/${submissionId}`;
		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
			body: JSON.stringify({ data: data, location_id: locationId }),
		});
		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				console.error(errorDetails);
				throw new Error(errorDetails.detail);
			});
		}
		return await response.json();
	} catch (exception: any) {
		console.error("Failed to update submission ", exception);
		throw new Error(exception);
	}
};

export async function exportSubmissionsCsvApi(params: {
	form_id: number | string;
	form_mode?: number;
	offset?: number;
	limit?: number;
	sort?: string;
	sort_direction?: string;
	search_value?: string;
	column_filter?: Record<string, string[]>;
	location_id?: string;
	include_deleted?: boolean;
	date_range?: string[];
	is_approved?: boolean;
}): Promise<Blob> {
	const urlParams = new URLSearchParams();
	urlParams.append("form_id", String(params.form_id));
	if (params.form_mode !== undefined)
		urlParams.append("form_mode", String(params.form_mode));
	if (params.offset !== undefined)
		urlParams.append("offset", String(params.offset));
	if (params.limit !== undefined)
		urlParams.append("limit", String(params.limit));
	if (params.sort) urlParams.append("sort", params.sort);
	if (params.sort_direction)
		urlParams.append("sort_direction", params.sort_direction);
	if (params.search_value)
		urlParams.append("search_value", params.search_value);

	if (params.column_filter) {
		const encoded = btoa(JSON.stringify(params.column_filter));
		urlParams.append("column_filter", encoded);
	}
	if (params.location_id) urlParams.append("location_id", params.location_id);
	if (params.include_deleted)
		urlParams.append("include_deleted", String(params.include_deleted));
	if (params.date_range && params.date_range.length === 2) {
		urlParams.append("date_range", params.date_range.join(","));
	}
	if (params.is_approved !== undefined) {
		urlParams.append("is_approved", String(params.is_approved));
	}

	const token = await auth?.currentUser?.getIdToken();
	const url = `${VITE_ALFRED_SERVICE_URL}/submission/export-csv?${urlParams.toString()}`;

	const resp = await fetch(url, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});

	if (!resp.ok) {
		const text = await resp.text();
		throw new Error(`Failed to export CSV: ${resp.status} - ${text}`);
	}

	return resp.blob();
}

/**
 * Approves a submission through the QA process
 * @param submissionId - The ID of the submission to approve
 * @param options - Optional configuration for the approval process
 * @returns Promise containing the approval response
 * @throws Error if the approval request fails
 */
export const approveSubmission = async (
	submissionId: string | number,
	options: { isAsset: boolean } = { isAsset: true },
): Promise<unknown> => {
	const { isAsset } = options;

	try {
		const url = isAsset
			? `${VITE_ALFRED_SERVICE_URL}/submission/qa/approve/asset/${submissionId}`
			: `${VITE_ALFRED_SERVICE_URL}/submission/qa/approve/${submissionId}`;

		const token = await auth?.currentUser?.getIdToken();
		if (!token) {
			throw new Error("No authentication token available");
		}

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
		});

		const data = await response.json();

		if (!response.ok) {
			throw new Error(data.detail || "Failed to approve QA submission");
		}

		return {
			success: true,
			data,
		};
	} catch (error) {
		const errorMessage =
			error instanceof Error
				? error.message
				: "An unknown error occurred while approving the submission";

		throw new Error(`Failed to approve QA submission: ${errorMessage}`);
	}
};
