// src/constants/env.ts

// Function to safely parse JSON
const parseJson = <T>(jsonString: string, variableName: string): T => {
	try {
		return JSON.parse(jsonString);
	} catch (_error) {
		throw new Error(
			`Invalid JSON format for environment variable ${variableName}`,
		);
	}
};

// Parse Firebase Config
export const FIREBASE_CONFIG: FirebaseConfig = parseJson<FirebaseConfig>(
	import.meta.env.VITE_REACT_APP_FIREBASE_CONFIG,
	"VITE_REACT_APP_FIREBASE_CONFIG",
);

// Export Environment
export const ENV: "development" | "production" | "staging" = import.meta.env
	.VITE_ENV;

// Export OpenAI Credentials
export const VITE_OPENAI_API_KEY: string = import.meta.env.VITE_OPENAI_API_KEY;
export const VITE_OPENAI_ORG: string = import.meta.env.VITE_OPENAI_ORG;

// Export Optional Variables with Default Values
export const VITE_ADDRESS_VALIDATION_API_KEY: string =
	import.meta.env.VITE_ADDRESS_VALIDATION_API_KEY || "";
export const VITE_ALFRED_SERVICE_URL: string = import.meta.env
	.VITE_ALFRED_SERVICE_URL;
export const VITE_OCR_AUTHORIZATION_TOKEN: string =
	import.meta.env.VITE_OCR_AUTHORIZATION_TOKEN || "";
export const VITE_GEOLOC_API_KEY: string =
	import.meta.env.VITE_GEOLOC_API_KEY || "";
export const VITE_PACKAGE_VERSION: string =
	import.meta.env.VITE_PACKAGE_VERSION || "2.1.07";
export const VITE_SENTRY_DSN: string = import.meta.env.VITE_SENTRY_DSN || "";
export const VITE_SENTRY_AUTH_TOKEN: string =
	import.meta.env.VITE_SENTRY_AUTH_TOKEN || "";
export const VITE_REACT_SCAN_ENABLED: boolean =
	import.meta.env.VITE_REACT_SCAN_ENABLED === "true" || false;
export const VITE_CORE_API_URL: string =
	import.meta.env.VITE_CORE_API_URL || "https://api.facilityai.com";
