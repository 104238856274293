export const getMarkerColor = (healthScore: number): string => {
	if (healthScore >= 90) return "#8A2E8F"; // Brand Purple
	if (healthScore >= 80) return "#954580"; // Medium Purple
	if (healthScore >= 70) return "#732B5C"; // Dark Purple
	if (healthScore >= 60) return "#C84249"; // Deep Red-Pink
	return "#AC2C2B"; // Brand Red
};

export const getMarkerSVG = (healthScore: number): string => {
	const color = getMarkerColor(healthScore);
	return `
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <rect x="4" y="4" width="16" height="16" rx="8" fill="${color}"/>
        </svg>
    `;
};

export const getClusterSVG = (
	count: number,
	avgHealthScore: number,
): string => {
	const color = getMarkerColor(avgHealthScore);
	return `
        <svg width="60" height="60" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <filter id="clusterFog">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="4"/>
                    <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 0.25 0"/>
                </filter>
            </defs>
            <circle cx="30" cy="30" r="25" fill="${color}" filter="url(#clusterFog)"/>
            <circle cx="30" cy="30" r="20" fill="${color}" opacity="0.6"/>
            <circle cx="30" cy="30" r="15" fill="${color}" opacity="0.8"/>
            <text x="30" y="28" 
                  font-family="Arial" 
                  font-size="16" 
                  fill="#FFFFFF" 
                  text-anchor="middle" 
                  dominant-baseline="middle"
                  font-weight="bold">
                ${count}
            </text>
            <text x="30" y="42" 
                  font-family="Arial" 
                  font-size="12" 
                  fill="#FFFFFF" 
                  text-anchor="middle" 
                  dominant-baseline="middle"
                  font-weight="bold">
                ${Math.round(avgHealthScore)}%
            </text>
        </svg>
    `;
};
