import type { ProjectedSpendingChartData } from "@/models/organization/dashboard-analytics/dashboard-analytics.model";
import { Tooltip as AntTooltip, Spin } from "antd";
import { Card } from "antd";
import {
	BarElement,
	CategoryScale,
	ChartData,
	Chart as ChartJS,
	ChartOptions,
	Legend,
	LinearScale,
	Title,
	Tooltip,
} from "chart.js";
import { FC, useEffect, useMemo, useReducer, useRef } from "react";
import { Bar } from "react-chartjs-2";
ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
);

interface PredSpendChartProps {
	chartData?: ProjectedSpendingChartData;
	className?: string;
	isYAxisReplCost?: boolean;
}

type BarChartData = ChartData<"bar">;
type BarChartOptions = ChartOptions<"bar">;

interface ChartState {
	data: BarChartData;
	options: BarChartOptions;
	isYAxisReplCost: boolean;
	loading: boolean;
	processing: boolean;
	error: string | null;
	isTextTruncated: boolean;
}

type ChartAction =
	| {
			type: "SET_DATA";
			payload: { data: BarChartData; options?: Partial<BarChartOptions> };
	  }
	| { type: "SET_IS_Y_AXIS_REPL_COST"; payload: boolean }
	| { type: "SET_LOADING"; payload: boolean }
	| { type: "SET_PROCESSING"; payload: boolean }
	| { type: "SET_ERROR"; payload: string | null }
	| { type: "SET_TEXT_TRUNCATED"; payload: boolean };

const initialChartState: ChartState = {
	data: {
		labels: [],
		datasets: [],
	},
	options: {
		responsive: true,
		maintainAspectRatio: false,
		interaction: {
			mode: "nearest",
			intersect: true,
		},
		plugins: {
			datalabels: {
				display: false,
			},
			dragData: false,
			legend: {
				position: "top",
				display: true,
				labels: {
					font: { size: 10 },
				},
				align: "center",
			},
			title: {
				display: true,
				text: "Projected Spending by Replacement Year and Asset Type",
				font: { size: 16 },
				padding: {
					top: 10,
					bottom: 10,
				},
			},
			tooltip: {
				callbacks: {
					label: (context) => context.dataset.label || "",
				},
			},
		},
		scales: {
			x: {
				title: {
					display: true,
					text: "Replacement Year",
					padding: { top: 10 },
				},
				ticks: {
					autoSkip: true,
					maxRotation: 45,
					minRotation: 45,
				},
			},
			y: {
				stacked: true,
				beginAtZero: true,
				ticks: {
					display: false,
					callback: (value) => {
						if (typeof value !== "number") return "";
						if (value >= 1000000) {
							return `$${(value / 1000000).toFixed(1)}M`;
						}
						if (value >= 1000) {
							return `$${(value / 1000).toFixed(0)}K`;
						}
						return `$${value}`;
					},
				},
				grid: {
					display: true,
				},
				title: {
					display: true,
					text: "Projected Cost",
					padding: { bottom: 10 },
				},
			},
		},
	},
	isYAxisReplCost: true,
	loading: true,
	processing: false,
	error: null,
	isTextTruncated: false,
};

const chartReducer = (state: ChartState, action: ChartAction): ChartState => {
	switch (action.type) {
		case "SET_DATA":
			return {
				...state,
				data: action.payload.data,
				options: action.payload.options
					? {
							...state.options,
							...action.payload.options,
						}
					: state.options,
				processing: false,
			};
		case "SET_IS_Y_AXIS_REPL_COST":
			return { ...state, isYAxisReplCost: action.payload };
		case "SET_LOADING":
			return { ...state, loading: action.payload };
		case "SET_PROCESSING":
			return { ...state, processing: action.payload };
		case "SET_ERROR":
			return {
				...state,
				error: action.payload,
				loading: false,
				processing: false,
			};
		case "SET_TEXT_TRUNCATED":
			return { ...state, isTextTruncated: action.payload };
		default:
			return state;
	}
};

const PredSpendChart: FC<PredSpendChartProps> = ({
	chartData,
	className = "",
	isYAxisReplCost: propIsYAxisReplCost,
}: PredSpendChartProps) => {
	const [state, dispatch] = useReducer(chartReducer, initialChartState);
	const {
		data,
		options,
		isYAxisReplCost,
		loading,
		processing,
		error,
		isTextTruncated,
	} = state;

	const getChartOptions = useMemo(() => {
		return (
			isYAxisReplCost: boolean,
			countData: Record<string, Record<string, number>>,
			valueData: Record<string, Record<string, number>>,
		): ChartOptions<"bar"> => ({
			responsive: true,
			maintainAspectRatio: false,
			resizeDelay: 50,
			layout: {
				autoPadding: true,
			},
			plugins: {
				datalabels: {
					display: false,
				},
				dragData: false,
				legend: {
					position: "top",
					display: true,
					labels: {
						boxWidth: 15,
						font: { size: 10 },
						padding: 10,
					},
					align: "center",
					fullSize: true,
				},
				title: {
					display: true,
					text: isYAxisReplCost
						? "Projected Spending by Replacement Year and Asset Type"
						: "Asset Count by Replacement Year and Asset Type",
					font: { size: 16 },
					padding: {
						bottom: 5,
					},
				},
				tooltip: {
					callbacks: {
						label: (context: any) => {
							const type = context.dataset.label;
							const year = context.label;
							const count = countData[year]?.[type] || 0;
							const value = valueData[year]?.[type] || 0;

							const formattedValue = new Intl.NumberFormat("en-US", {
								style: "currency",
								currency: "USD",
								minimumFractionDigits: 0,
								maximumFractionDigits: 0,
							}).format(value);

							return [
								`${type}:`,
								`  ${isYAxisReplCost ? `Cost: ${formattedValue}` : `Count: ${count} assets`}`,
								`  ${isYAxisReplCost ? `Count: ${count} assets` : `Value: ${formattedValue}`}`,
							];
						},
					},
				},
			},
			scales: {
				x: {
					title: {
						display: true,
						text: "Replacement Year",
						padding: { top: 10 },
					},
					ticks: {
						autoSkip: true,
						maxRotation: 45,
						minRotation: 45,
					},
				},
				y: {
					stacked: true,
					beginAtZero: true,
					ticks: {
						display: true,
						padding: 10,
						callback: (value: any) => {
							if (isYAxisReplCost) {
								if (value >= 1000000) {
									return `$${(value / 1000000).toFixed(1)}M`;
								}
								if (value >= 1000) {
									return `$${(value / 1000).toFixed(0)}K`;
								}
								return `$${value}`;
							}
							return value;
						},
					},
					grid: {
						display: true,
					},
					title: {
						display: true,
						text: isYAxisReplCost ? "Projected Cost" : "Asset Count",
						padding: { bottom: 10 },
					},
				},
			},
		});
	}, []);

	const assetTypeColors = useMemo(() => {
		const baseColors = [
			"#AC2C2B",
			"#8A2E8F",
			"#C84249",
			"#732B5C",
			"#CF7180",
			"#954580",
			"#C95D76",
			"#B33B42",
			"#A13B8F",
			"#BC5C74",
		];

		const colorMap: Record<string, string> = {};

		if (!chartData) return colorMap;

		chartData.assetTypes.forEach((type, index) => {
			colorMap[type] = baseColors[index % baseColors.length];
		});

		colorMap["NOT ASSIGNED"] = "#800020";
		return colorMap;
	}, [chartData?.assetTypes]);

	useEffect(() => {
		if (chartData) {
			dispatch({ type: "SET_LOADING", payload: false });
			dispatch({ type: "SET_ERROR", payload: null });
			dispatch({ type: "SET_PROCESSING", payload: true });
		} else if (!loading) {
			dispatch({ type: "SET_ERROR", payload: "No chart data available" });
		}
	}, [chartData, loading]);

	useEffect(() => {
		if (chartData) {
			const {
				yearsWithAssets,
				assetTypes,
				assetValuesByYearAndType,
				assetCountByYearAndType,
			} = chartData;

			if (!yearsWithAssets?.length || !assetTypes?.length) {
				dispatch({
					type: "SET_DATA",
					payload: {
						data: {
							labels: [],
							datasets: [],
						},
						options: getChartOptions(isYAxisReplCost, {}, {}),
					},
				});
				dispatch({ type: "SET_LOADING", payload: false });
				dispatch({ type: "SET_PROCESSING", payload: false });
				return;
			}

			const datasets = assetTypes.map((type) => {
				const color =
					assetTypeColors[type] ||
					`hsla(${Math.random() * 360}, 70%, 60%, 0.8)`;

				return {
					label: type,
					data: yearsWithAssets.map((year) => {
						const yearKey = String(year);
						return isYAxisReplCost
							? assetValuesByYearAndType[yearKey]?.[type] || 0
							: assetCountByYearAndType[yearKey]?.[type] || 0;
					}),
					backgroundColor: color,
					stack: "stack1",
				};
			});

			dispatch({
				type: "SET_DATA",
				payload: {
					data: {
						labels: yearsWithAssets,
						datasets,
					},
					options: getChartOptions(
						isYAxisReplCost,
						assetCountByYearAndType,
						assetValuesByYearAndType,
					),
				},
			});
		}
	}, [chartData, isYAxisReplCost, assetTypeColors, getChartOptions]);

	const noteTextRef = useRef<HTMLSpanElement>(null);

	useEffect(() => {
		const checkTextTruncation = () => {
			if (noteTextRef.current) {
				const isTruncated =
					noteTextRef.current.scrollHeight > noteTextRef.current.clientHeight;
				dispatch({ type: "SET_TEXT_TRUNCATED", payload: isTruncated });
			}
		};
		checkTextTruncation();

		const handleResize = () => {
			checkTextTruncation();
		};

		window.addEventListener("resize", handleResize);

		if (data?.labels?.length) {
			const timer = setTimeout(checkTextTruncation, 300);
			return () => {
				clearTimeout(timer);
				window.removeEventListener("resize", handleResize);
			};
		}

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [data]);

	useEffect(() => {
		if (
			propIsYAxisReplCost !== undefined &&
			propIsYAxisReplCost !== state.isYAxisReplCost
		) {
			dispatch({
				type: "SET_IS_Y_AXIS_REPL_COST",
				payload: propIsYAxisReplCost,
			});
			dispatch({ type: "SET_PROCESSING", payload: true });
		}
	}, [propIsYAxisReplCost, state.isYAxisReplCost]);

	return (
		<div
			className={className}
			style={{ width: "100%", height: "100%", minHeight: "450px" }}
		>
			<Card className="border-0" style={{ height: "100%", width: "100%" }}>
				<div style={{ height: "100%", padding: "0.75rem" }}>
					{loading ? (
						<div className="flex justify-center items-center h-full w-full">
							<Spin size="large" tip="Loading asset data..." />
						</div>
					) : processing ? (
						<div className="flex justify-center items-center h-full w-full">
							<Spin size="large" tip="Processing chart data..." />
						</div>
					) : error ? (
						<div className="flex justify-center items-center h-full w-full">
							<div>
								<p>{error}</p>
							</div>
						</div>
					) : data?.labels &&
						data.labels.length > 0 &&
						data?.datasets &&
						data.datasets.length > 0 ? (
						<div
							className="chart-container"
							style={{
								position: "relative",
								height: "100%",
								width: "100%",
								minHeight: "350px",
								display: "flex",
								flexDirection: "column",
								gap: "8px",
							}}
						>
							<div
								style={{
									position: "relative",
									height: "calc(100% - 32px)",
									width: "100%",
								}}
							>
								<Bar
									options={{
										...options,
										responsive: true,
										maintainAspectRatio: false,
									}}
									data={data}
									redraw={false}
								/>
							</div>
							{chartData?.hasEstimatedDates && (
								<div
									style={{
										width: "100%",
										textAlign: "center",
										position: "relative",
										zIndex: 1,
										marginTop: "auto",
										padding: "4px 8px",
										minHeight: "42px",
									}}
								>
									<AntTooltip
										title="Replacement years are estimated based on asset condition, useful life, and industry standards"
										popupVisible={isTextTruncated}
										trigger={isTextTruncated ? ["hover", "focus", "click"] : []}
									>
										<span
											ref={noteTextRef}
											className="text-sm text-muted"
											style={{
												display: "-webkit-box",
												WebkitBoxOrient: "vertical",
												WebkitLineClamp: 2,
												overflow: "hidden",
												textOverflow: "ellipsis",
												lineHeight: "1.3",
												maxWidth: "100%",
											}}
										>
											* Replacement years are estimated based on asset
											condition, useful life, and industry standards
										</span>
									</AntTooltip>
								</div>
							)}
						</div>
					) : (
						<div className="flex justify-center items-center h-full w-full">
							<div className="text-center">
								<p>No asset data available</p>
								<small>
									There are no assets with replacement year data in the system.
								</small>
							</div>
						</div>
					)}
				</div>
			</Card>
		</div>
	);
};

export { PredSpendChart };
