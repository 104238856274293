// StatsCard.tsx
import { Badge, Card, Col, Row, Skeleton, Tooltip } from "antd";
import React, { FC } from "react";
import { BiTrendingDown, BiTrendingUp } from "react-icons/bi";

const DATA_TEXT_COLOR = "#2c2c2c";
const NAME_TEXT_COLOR = "#6c757d";
const ICON_COLOR = "#0d6efd";
const TREND_UP_COLOR = "#198754";
const TREND_DOWN_COLOR = "#dc3545";

interface StatsCardProps {
	id: string;
	name: string;
	data: string | number;
	icon: React.ReactNode;
	color?: string;
	lineBgClass?: string;
	trend?: number;
	showTrend?: boolean;
	popoverContent?: string;
	formatData?: (val: string | number) => string;
	formatTrend?: (trend: number) => string;
	isLoading?: boolean;
}

export const StatsCard: FC<StatsCardProps> = ({
	id,
	name,
	data,
	icon,
	color = ICON_COLOR,
	lineBgClass = "",
	trend,
	showTrend = false,
	popoverContent,
	formatData = (v) => (typeof v === "number" ? v.toLocaleString() : v),
	formatTrend = (t) => `${t.toLocaleString()}%`,
	isLoading = false,
}) => {
	const formattedData = formatData(data);

	const isTrendUp = showTrend && trend !== undefined && trend > 0;
	const isTrendDown = showTrend && trend !== undefined && trend < 0;
	const arrowColor = isTrendUp
		? TREND_UP_COLOR
		: isTrendDown
			? TREND_DOWN_COLOR
			: ICON_COLOR;
	const badgeColor = arrowColor;

	const content = (
		<Card
			className={`my-3 border-0 ${lineBgClass}`}
			id={id}
			style={{
				borderRadius: "0.5rem",
				backgroundColor: "#fff",
				position: "relative",
				overflow: "hidden",
				height: "100%",
				minHeight: "100%",
			}}
			variant="borderless"
		>
			<Row className="h-100" gutter={16}>
				<Col
					span={24}
					className="d-flex align-items-center justify-content-between h-100 p-3"
				>
					<div className="d-flex flex-column justify-content-center w-50">
						{isLoading ? (
							<>
								<Skeleton.Input style={{ width: 80, height: 24 }} active />
								<Skeleton.Input
									style={{ width: 100, height: 16, marginTop: 8 }}
									active
								/>
							</>
						) : (
							<>
								<span
									style={{
										color: DATA_TEXT_COLOR,
										fontSize: "1.5rem",
										fontWeight: 600,
									}}
								>
									{formattedData}
								</span>
								<p
									className="m-0"
									style={{ color: NAME_TEXT_COLOR, fontSize: "0.9rem" }}
								>
									{name}
								</p>
							</>
						)}
					</div>
					<div className="d-flex align-items-center justify-content-end w-50">
						{isLoading ? (
							<Skeleton.Avatar active shape="square" size="large" />
						) : (
							<>
								<div className="d-flex align-items-center justify-content-end position-absolute top-0 end-2 m-2">
									{showTrend && !isLoading && isTrendUp && (
										<BiTrendingUp
											style={{ color: arrowColor }}
											className="fs-4 me-2"
										/>
									)}
									{showTrend && !isLoading && isTrendDown && (
										<BiTrendingDown
											style={{ color: arrowColor }}
											className="fs-4 me-2"
										/>
									)}
									{showTrend && !isLoading && trend !== undefined && (
										<Badge
											count={formatTrend(trend)}
											style={{ backgroundColor: badgeColor }}
										/>
									)}
								</div>
								<span
									style={{ color: color, fontSize: "2rem" }}
									className="mt-4"
								>
									{icon}
								</span>
							</>
						)}
					</div>
				</Col>
			</Row>
		</Card>
	);

	if (!popoverContent || isLoading) {
		return content;
	}

	return (
		<Tooltip title={popoverContent} placement="top">
			{content}
		</Tooltip>
	);
};
