import { StatsCard } from "@/layouts/signed-in/views/Dashboard/StatsCard/StatsCard";
import { getDashboardData } from "@/services/asset-service";
import { useUserStore } from "@/stores/user-store";
import { Button, Card, Col, Layout, Row, Spin } from "antd";
import {
	useCallback,
	useEffect,
	useMemo,
	useReducer,
	useRef,
	useState,
} from "react";
import "./Dashboard.scss";
import type { CoreDashboardAnalytics } from "@/models/organization/dashboard-analytics/dashboard-analytics.model";
import { ReloadOutlined, ZoomOutOutlined } from "@ant-design/icons";
import { AiOutlineNumber } from "react-icons/ai";
import { BsBoxes, BsCurrencyDollar, BsHourglass } from "react-icons/bs";
import { GiHealthNormal } from "react-icons/gi";
import { GoogleHeatmap, GoogleHeatmapRef } from "./google-heat-map";
import { PredSpendChart } from "./pred-spend-chart";

const HEALTH_COLORS = {
	GOOD: "#198754",
	WARNING: "#FFC107",
	DANGER: "#dc3545",
};

const CARD_TITLE_STYLE = {
	fontSize: "1.5rem",
	fontWeight: 600,
	color: "rgb(33, 37, 41)",
	margin: 0,
};

interface DashboardState extends CoreDashboardAnalytics {
	isLoading: boolean;
	error: string | null;
}

type DashboardAction =
	| {
			type: "SET_DATA";
			payload: CoreDashboardAnalytics;
	  }
	| { type: "SET_LOADING"; payload: boolean }
	| { type: "SET_ERROR"; payload: string | null }
	| { type: "BULK_SET"; payload: Partial<DashboardState> };

// Initial State //////////////////////////////////////////
const initialState: DashboardState = {
	locations: [],
	analytics: {
		healthScore: 0,
		dollarAmountPastUsefulLife: 0,
		totalBudget: 0,
	},
	assets: [],
	projectedSpendingChartData: {
		yearsWithAssets: [],
		assetTypes: [],
		assetValuesByYearAndType: {},
		assetCountByYearAndType: {},
		totalBudget: 0,
		hasEstimatedDates: false,
	},
	isLoading: false,
	error: null,
};

// Reducer ////////////////////////////////////////////////
const dashboardReducer = (
	state: DashboardState,
	action: DashboardAction,
): DashboardState => {
	switch (action.type) {
		case "SET_DATA":
			return {
				...state,
				locations: action.payload.locations,
				analytics: action.payload.analytics,
				assets: action.payload.assets,
				projectedSpendingChartData: action.payload.projectedSpendingChartData,
				isLoading: false,
				error: null,
			};
		case "SET_LOADING":
			return {
				...state,
				isLoading: action.payload,
			};
		case "SET_ERROR":
			return {
				...state,
				error: action.payload,
				isLoading: false,
			};
		case "BULK_SET":
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

// Component ////////////////////////////////////////////////
const Dashboard = () => {
	const mapRef = useRef<GoogleHeatmapRef>(null);
	const user = useUserStore((state) => state.user);
	const organization_id = user?.organization_id ?? 0;

	const [state, dispatch] = useReducer(dashboardReducer, initialState);
	const { locations, analytics, projectedSpendingChartData, isLoading, error } =
		state;

	const [isYAxisReplCost, setIsYAxisReplCost] = useState(true);

	const memoizedPoorHealthLocations = useMemo(
		() => locations.filter((loc) => (loc.health?.healthScore ?? 0) < 60).length,
		[locations],
	);

	const getHealthScoreColor = (score: number) => {
		if (score >= 70) return HEALTH_COLORS.GOOD;
		if (score >= 50) return HEALTH_COLORS.WARNING;
		return HEALTH_COLORS.DANGER;
	};

	const getPoorLocationsColor = (poorCount: number, total: number) => {
		if (total === 0) return HEALTH_COLORS.GOOD;
		const percent = (poorCount / total) * 100;
		if (percent < 30) return HEALTH_COLORS.GOOD;
		if (percent < 50) return HEALTH_COLORS.WARNING;
		return HEALTH_COLORS.DANGER;
	};

	const fetchDashboardData = useCallback(async () => {
		try {
			dispatch({ type: "SET_LOADING", payload: true });
			dispatch({ type: "SET_ERROR", payload: null });

			const data = await getDashboardData();

			const processedLocations = (data.locations || []).map(
				(location: any) => ({
					...location,
					health: {
						healthScore: location.healthScore?.healthScore || 0,
						locationId: location.id,
					},
				}),
			);

			const analytics = {
				healthScore: Number(data.analytics?.healthScore) || 0,
				dollarAmountPastUsefulLife:
					Number(data.analytics?.dollarAmountPastUsefulLife) || 0,
				totalBudget: Number(data.analytics?.totalBudget) || 0,
			};

			const processedChartData = {
				yearsWithAssets: data.projectedSpendingChartData?.yearsWithAssets || [],
				assetTypes: data.projectedSpendingChartData?.assetTypes || [],
				assetValuesByYearAndType:
					data.projectedSpendingChartData?.assetValuesByYearAndType || {},
				assetCountByYearAndType:
					data.projectedSpendingChartData?.assetCountByYearAndType || {},
				totalBudget: data.projectedSpendingChartData?.totalBudget || 0,
				hasEstimatedDates:
					data.projectedSpendingChartData?.hasEstimatedDates || false,
			};

			dispatch({
				type: "SET_DATA",
				payload: {
					locations: processedLocations,
					analytics,
					assets: data.assets || [],
					projectedSpendingChartData: processedChartData,
				},
			});
		} catch (error) {
			dispatch({ type: "SET_ERROR", payload: "Error fetching dashboard data" });
			console.error("Error fetching dashboard data:", error);
		}
	}, []);

	const handleRefreshData = useCallback(() => {
		fetchDashboardData();
	}, [fetchDashboardData]);

	useEffect(() => {
		if (organization_id) {
			fetchDashboardData();
		}
	}, [organization_id, fetchDashboardData]);

	const toggleYAxis = () => {
		setIsYAxisReplCost(!isYAxisReplCost);
	};

	if (isLoading) {
		return (
			<div className="flex justify-center items-center h-screen">
				<Spin size="large" tip="Loading dashboard data..." />
			</div>
		);
	}

	if (error) {
		return (
			<div className="flex justify-center items-center h-screen">
				<div className="text-center">
					<h2 className="text-red-500 mb-4">Error Loading Dashboard</h2>
					<p>{error}</p>
				</div>
			</div>
		);
	}

	// Render ////////////////////////////////////////////////
	return (
		<Layout className="dashboard-container">
			{/* Stats Cards Row */}
			<Row
				gutter={[
					{ xs: 16, sm: 24 },
					{ xs: 8, sm: 16 },
				]}
				className="stat-cards-row mb-4"
				style={{ marginTop: "0px" }}
			>
				{/* Average Health Score */}
				<Col xs={24} sm={12} lg={6} className="stats-card-col">
					<StatsCard
						id="average-health-score"
						name="Average Health Score"
						data={analytics.healthScore ?? 0}
						icon={<GiHealthNormal />}
						color={getHealthScoreColor(analytics.healthScore ?? 0)}
						formatData={(value) => `${Number(value).toFixed(2)}%`}
						isLoading={isLoading}
					/>
				</Col>

				{/* Locations in Poor Health */}
				<Col xs={24} sm={12} lg={6} className="stats-card-col">
					<StatsCard
						id="locations-poor-health"
						name="Locations in Poor Health"
						data={`${memoizedPoorHealthLocations} / ${locations.length}`}
						icon={<AiOutlineNumber />}
						color={getPoorLocationsColor(
							memoizedPoorHealthLocations,
							locations.length,
						)}
						isLoading={isLoading}
					/>
				</Col>

				{/* Total Budget Required */}
				<Col xs={24} sm={12} lg={6} className="stats-card-col">
					<StatsCard
						id="total-budget"
						name="Total 10-Year Budget"
						data={analytics.totalBudget ?? 0}
						icon={<BsCurrencyDollar />}
						formatData={(value) => {
							const numValue = Number(value);
							if (Number.isNaN(numValue)) return "$0";
							if (numValue >= 1000000) {
								return `$${(numValue / 1000000).toFixed(1)}M`;
							}
							if (numValue >= 1000) {
								return `$${(numValue / 1000).toFixed(0)}K`;
							}
							return `$${numValue.toFixed(0)}`;
						}}
						isLoading={isLoading}
					/>
				</Col>

				{/* Amount Past Useful Life */}
				<Col xs={24} sm={12} lg={6} className="stats-card-col">
					<StatsCard
						id="past-useful-life"
						name="Amount Past Useful Life"
						data={analytics.dollarAmountPastUsefulLife ?? 0}
						icon={<BsHourglass />}
						color="#6c757d"
						formatData={(value) => {
							const numValue = Number(value);
							if (Number.isNaN(numValue)) return "$0";
							if (numValue >= 1000000) {
								return `$${(numValue / 1000000).toFixed(1)}M`;
							}
							if (numValue >= 1000) {
								return `$${(numValue / 1000).toFixed(0)}K`;
							}
							return `$${numValue.toFixed(0)}`;
						}}
						isLoading={isLoading}
					/>
				</Col>
			</Row>

			{/* Top Row: Map and Asset Graph side by side */}
			<Row gutter={[24, 24]} className="chart-row mt-4">
				{/* Left: Heatmap */}
				<Col xs={24} lg={12} className="mb-6">
					<Card
						title={
							<div className="flex justify-between items-center">
								<span style={CARD_TITLE_STYLE}>Portfolio Health</span>
								<Button
									icon={<ZoomOutOutlined />}
									onClick={() => mapRef.current?.resetView()}
									size="small"
								>
									Reset View
								</Button>
							</div>
						}
						className="dashboard-chart-card"
						bodyStyle={{ height: "calc(100% - 57px)", padding: "12px" }}
						headStyle={{
							borderBottom: "none",
							padding: "16px 24px",
							backgroundColor: "rgba(33, 37, 41, 0.03)",
							color: "rgb(33, 37, 41)",
						}}
					>
						<GoogleHeatmap ref={mapRef} locations={locations} />
					</Card>
				</Col>

				{/* Right: Predicted Spend 10-Years */}
				<Col xs={24} lg={12} className="mb-6">
					<Card
						title={
							<div className="flex justify-between items-center">
								<span style={CARD_TITLE_STYLE}>Predictive Spend</span>
								<div>
									<Button
										icon={isYAxisReplCost ? <BsBoxes /> : <BsCurrencyDollar />}
										onClick={toggleYAxis}
										size="small"
										className="mr-2"
										title="Toggle between cost and count view"
									/>
									<Button
										icon={<ReloadOutlined />}
										onClick={handleRefreshData}
										size="small"
									>
										Refresh
									</Button>
								</div>
							</div>
						}
						className="dashboard-chart-card"
						bodyStyle={{ height: "calc(100% - 57px)", padding: "12px" }}
						headStyle={{
							borderBottom: "none",
							padding: "16px 24px",
							backgroundColor: "rgba(33, 37, 41, 0.03)",
							color: "rgb(33, 37, 41)",
						}}
					>
						<PredSpendChart
							chartData={projectedSpendingChartData}
							className="h-full w-full"
							isYAxisReplCost={isYAxisReplCost}
						/>
					</Card>
				</Col>
			</Row>
		</Layout>
	);
};

export { Dashboard };
